import React from "react";

const Settings = () => {
  return (
    <div style={{ flexGrow: 1, padding: 20 }}>
      <h1>Settings</h1>
      {/* Settings content here */}
    </div>
  );
};

export default Settings;
