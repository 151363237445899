import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { post } from "../../utils/fetchAPI";
import { LocalStorageService } from "../../utils/local-storage";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!email || !password) {
      setErrorMessage("Please fill out all fields");
      return;
    }

    try {
      const response = await post(
        "https://api-3zero.shukhee.com/v1/api/admin/auth/login",
        { email, password }
      );

      if (response.data && response.data.success) {
        const { accessToken } = response.data.data;
        LocalStorageService.set("user", {
          token: accessToken,
        });
        navigate("/dashboard");
      } else {
        setErrorMessage("Invalid login credentials");
      }
    } catch (error) {
      if (error.response) {
        setErrorMessage(
          error.response.data.message || "An error occurred during login"
        );
      } else {
        setErrorMessage("Network error. Please try again.");
      }
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
      <div className="relative px-6 py-3 mx-auto w-full sm:w-96 text-center">
        <style scoped>
          {`
            input:focus {
              border-color: var(--tw-ring-color);
            }
          `}
        </style>

        <span className="text-2xl text-lightSecondary">3Zero Admin Login</span>
        <div className="mt-4 bg-white shadow-md rounded-lg text-left">
          <div className="h-2 bg-primary rounded-t-md"></div>
          <div className="border px-8 py-6">
            {errorMessage && <p className="text-red-500">{errorMessage}</p>}
            <label className="label block font-semibold" htmlFor="email">
              Email
            </label>
            <input
              className="w-full h-10 mt-2 px-3 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <label
              className="label mt-3 block font-semibold"
              htmlFor="password"
            >
              Password
            </label>
            <input
              className="w-full h-10 mt-2 px-3 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />

            <div className="flex items-center mt-4">
              <button
                type="submit"
                onClick={handleSubmit}
                className="px-4 py-2 bg-secondary text-white rounded-md"
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
