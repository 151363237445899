// userTable.jsx
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { FaPen } from "react-icons/fa";
import EditStatusModal from "./EditStatusModal";

const UserTable = ({
  users: initialUsers,
  totalRows,
  page,
  limit,
  setPage,
  setLimit,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState(initialUsers);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Username",
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => row.details?.address || "N/A", // Safely access address
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <div className="flex justify-center">
          <h2
            className={`min-w-fit w-fit px-3 py-1 text-sm rounded-md capitalize text-center ${
              row.status === "active"
                ? "bg-green-200"
                : row.status === "inactive"
                ? "bg-red-200"
                : row.status === "Pending"
                ? "bg-slate-300"
                : "bg-gray-200"
            }`}
          >
            {row.status === "active"
              ? "Active"
              : row.status === "inactive"
              ? "Inactive"
              : row.status === "Pending"
              ? "Pending"
              : "Unknown"}
          </h2>
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <button
          onClick={() => {
            setSelectedUser(row);
            setIsModalOpen(true);
          }}
          className="bg-blue-600 text-white p-2 rounded hover:bg-blue-700 flex items-center justify-center"
          aria-label="Edit status"
        >
          <FaPen className="text-white" />
        </button>
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#f9fafb",
        fontWeight: "bold",
      },
    },
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newLimit) => {
    setLimit(newLimit);
    setPage(1);
  };

  const handleStatusUpdate = (userId, newStatus) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user._id === userId ? { ...user, status: newStatus } : user
      )
    );
  };

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-full">
      <DataTable
        columns={columns}
        data={users}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationDefaultPage={page}
        paginationPerPage={limit}
        paginationRowsPerPageOptions={[5, 10, 20, 50]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        highlightOnHover
        responsive
        customStyles={customStyles}
        subHeader
        subHeaderComponent={
          <div className="flex justify-between items-center w-full">
            <h1 className="text-xl font-bold">Users</h1>
            {/* <input
              type="text"
              placeholder="Search"
              className="border rounded p-2"
              onChange={(e) => console.log(e.target.value)}
            /> */}
          </div>
        }
      />
      <EditStatusModal
        user={selectedUser}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onStatusUpdate={handleStatusUpdate}
      />
    </div>
  );
};

export default UserTable;
