import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../utils/fetchAPI";

const initialState = {
  user: "" || JSON.parse(localStorage.getItem("user")),
  userRoles: "" || JSON.parse(localStorage.getItem("userRoles")),
  isLoggedIn: !!JSON.parse(localStorage.getItem("user"))?.token,
  isLoading: false,
  authError: null,
  selectedRole: "" || JSON.parse(localStorage.getItem("selectedRole")),
};

export const login = createAsyncThunk(
  "auth/login",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await post("/login", payload);
      if (response?.status === 200) return response.data;
      else return rejectWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    removeAuthInfo: (state, action) => {
      localStorage.removeItem("user");
      state.user = "";
      state.userRoles = "";
      state.isLoggedIn = false;
    },
    setAuthInfo: (state, action) => {
      let { userRoles, ...user } = action.payload
      state.user = user;
      state.userRoles = userRoles;
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("userRoles", JSON.stringify(userRoles));
    },
    setSelectedRole: (state, action) => {
      state.selectedRole = action.payload;
      localStorage.setItem("selectedRole", JSON.stringify(action.payload));
    },

  },

});

export default authSlice.reducer;
export const { removeAuthInfo, setAuthInfo, setSelectedRole } = authSlice.actions;
