import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getWithParams } from "../../utils/fetchAPI";
import UserTable from "./userTable";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const navigate = useNavigate();

  const fetchUsers = async (page, limit) => {
    setLoading(true);
    try {
      const response = await getWithParams("admin/users", { page, limit });

      if (response?.status === 200) {
        setUsers(response?.data?.data?.docs || []);
        setTotalRows(response?.data?.data?.totalDocs);
      } else {
        setUsers([]);
      }
    } catch (err) {
      setError("Failed to fetch users");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("user");
    if (!isLoggedIn) {
      navigate("/login");
      return;
    }

    fetchUsers(page, limit);
  }, [page, limit, navigate]);

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === "user" && !e.newValue) {
        navigate("/login");
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [navigate]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="w-full">
      <UserTable
        users={users}
        totalRows={totalRows}
        page={page}
        limit={limit}
        setPage={setPage}
        setLimit={setLimit}
      />
    </div>
  );
};

export default Users;
