import React from "react";
import theme from "./theme";
import { ThemeProvider } from "@emotion/react";
import "./index.css";
import AppRoutes   from "./routes/app-routes";

const App = () => (
  <ThemeProvider theme={theme}>
      <AppRoutes/>
  </ThemeProvider>
);

export default App;
