import React from "react";
import { FaCog, FaHome, FaUsers } from "react-icons/fa";
import { HiMenuAlt2 } from "react-icons/hi";
import { Link } from "react-router-dom";

const SideBar = ({ isCollapsed, setIsCollapsed }) => {
  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div
      className={`fixed top-0 left-0 h-screen bg-primary text-white flex flex-col transition-all duration-300 ${
        isCollapsed ? "w-16" : "w-64"
      }`}
    >
      <div className="flex items-center justify-between p-4">
        <button onClick={toggleSidebar} className="p-2">
          <HiMenuAlt2 className="w-6 h-6" />
        </button>
        {!isCollapsed && (
          <div className="flex-1 flex justify-center items-center text-xl font-bold">
            Admin Portal
          </div>
        )}
      </div>
      <nav className="flex-1 flex flex-col mt-8">
        <Link
          to="/dashboard"
          className={`flex items-center p-2 hover:bg-gray-700 rounded w-full ${
            isCollapsed ? "justify-center" : ""
          }`}
        >
          <FaHome className="w-6 h-6" />
          {!isCollapsed && <span className="ml-4 text-left">Dashboard</span>}
        </Link>
        <Link
          to="/users"
          className={`flex items-center p-2 hover:bg-gray-700 rounded w-full ${
            isCollapsed ? "justify-center" : ""
          }`}
        >
          <FaUsers className="w-6 h-6" />
          {!isCollapsed && <span className="ml-4 text-left">Users</span>}
        </Link>
        <Link
          to="/settings"
          className={`flex items-center p-2 hover:bg-gray-700 rounded w-full ${
            isCollapsed ? "justify-center" : ""
          }`}
        >
          <FaCog className="w-6 h-6" />
          {!isCollapsed && <span className="ml-4 text-left">Settings</span>}
        </Link>
      </nav>
    </div>
  );
};

export default SideBar;
