import React, { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import NavBar from "../components/nav-bar/nav-bar";
import SideBar from "../components/side-bar/side-bar";
import { LocalStorageService } from "../utils/local-storage";

const PrivateRoute = () => {
  const isLoggedIn = !!LocalStorageService.get("user");

  const [isCollapsed, setIsCollapsed] = useState(true);

  return isLoggedIn ? (
    <div className="flex h-screen">
      <SideBar isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />

      {/* Adjust content width based on sidebar collapse status */}
      <div
        className={`flex-1 flex flex-col transition-all duration-300 ${
          isCollapsed ? "ml-16" : "ml-64"
        }`}
      >
        <NavBar />
        <div className="flex-grow p-6 bg-gray-100 overflow-y-auto">
          <div className="w-full">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
