// EditStatusModal.js
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { put } from "../../utils/fetchAPI";

Modal.setAppElement("#root");

const EditStatusModal = ({ user, isOpen, onClose, onStatusUpdate }) => {
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      setStatus(user.status);
    }
  }, [user]);

  const handleSave = async () => {
    setLoading(true);
    try {
      const response = await put(`/admin/users/update-status/${user._id}`, {
        status,
      });
      if (response?.status === 200) {
        onStatusUpdate(user._id, status);
        onClose();
      }
    } catch (err) {
      console.error("Error updating status", err);
      alert("Failed to update status. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="fixed inset-0 flex items-center justify-center m-4"
      overlayClassName="fixed inset-0 bg-black bg-opacity-60 backdrop-blur-sm"
    >
      <div className="relative w-full max-w-lg rounded-lg bg-white shadow-lg p-6">
        <div className="flex items-start justify-between">
          <h2 className="text-xl font-bold mb-4">Edit User Status</h2>
          <button
            onClick={onClose}
            className="relative h-8 w-8 rounded-lg text-blue-gray-500 transition-all hover:bg-blue-gray-500/10"
            type="button"
          >
            <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
                className="h-5 w-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </span>
          </button>
        </div>
        {user && (
          <div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Name:
              </label>
              <input
                type="text"
                value={user.name}
                disabled
                className="mt-1 border rounded-md p-2 w-full bg-gray-100"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Email:
              </label>
              <input
                type="text"
                value={user.email}
                disabled
                className="mt-1 border rounded-md p-2 w-full bg-gray-100"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Phone:
              </label>
              <input
                type="text"
                value={user.phone}
                disabled
                className="mt-1 border rounded-md p-2 w-full bg-gray-100"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Current Status:
              </label>
              <input
                type="text"
                value={user.status}
                disabled
                className="mt-1 border rounded-md p-2 w-full bg-gray-100"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                New Status:
              </label>
              <select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className="mt-1 border rounded-md p-2 w-full"
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
                <option value="suspended">Suspended</option>
              </select>
            </div>
            <div className="flex justify-end mt-4">
              <button
                onClick={handleSave}
                disabled={loading}
                className={`bg-blue-500 text-white px-4 py-2 rounded mr-2 ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {loading ? "Saving..." : "Save"}
              </button>
              <button
                onClick={onClose}
                className="bg-gray-300 text-black px-4 py-2 rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default EditStatusModal;
