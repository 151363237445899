import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "../pages/dashborad/dashborad";
import LogIn from "../pages/login/login";
import Settings from "../pages/settings/settings";
import Users from "../pages/users/users";
import PrivateRoute from "./private-route";
import PublicRoute from "./public-route";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        {/* Redirect the root to either dashboard or login based on authentication */}
        <Route path="/" element={<Navigate to="/dashboard" />} />

        {/* Public routes */}
        <Route element={<PublicRoute />}>
          <Route path="/login" element={<LogIn />} />
        </Route>

        {/* Private routes */}
        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/users" element={<Users />} />
          <Route path="/settings" element={<Settings />} />
        </Route>

        {/* Catch-all for 404 */}
        <Route path="*" element={<h1>404 not found</h1>} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
