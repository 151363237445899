import React from "react";
import { useNavigate } from "react-router-dom";
import { LocalStorageService } from "../../utils/local-storage";

const NavBar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    LocalStorageService.clear();
    navigate("/login");
  };

  return (
    <nav className="bg-lightSecondary text-white p-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-2xl font-bold">
          {/* <Link to="/">Admin Portal</Link> */}
        </div>
        <div className="hidden md:flex space-x-6">
          <button
            onClick={handleLogout}
            className="hover:bg-gray-700 p-2 rounded"
          >
            Logout
          </button>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
